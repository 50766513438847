import React from "react";

export default function Alert(props) {
    const textCapitalize =(text) =>{
        const lower=text.toLowerCase();
        return lower.charAt(0).toUpperCase()+lower.slice(1);
    }
  return (
    <div style={{height:'50px',marginBottom:'20px'}}>
    {props.alert && <div className={`alert alert-${props.alert.type} alert-dismissible fade show`} role="alert">
      <strong>{textCapitalize(props.alert.type)} : </strong> {props.alert.message}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>}
    </div>
  );
}
