import React, { useState } from "react";
import copy from "copy-to-clipboard";  
export default function TextForm(props) {
  
  const handleUppercaseclicked = () => {
    let newText = text.toUpperCase();
    setText(newText);
    props.showAlert('The text has been converted to uppercase', 'success');
  };
  const handleLowercaseclicked = () => {
    let newText = text.toLowerCase();
    setText(newText);
    props.showAlert('The text has been converted to lowecase', 'success');
  };
  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleCopyToClipboard = () =>{
    copy(text);
    props.showAlert('Text copied!', 'success');
  }
  const [text, setText] = useState("");
  return (
    <>
      <div className="container">
        <div className="mb-3">
          <h2>{props.heading}</h2>
          <textarea
            value={text}
            onChange={handleTextChange}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="8"
          ></textarea>
          <button disabled={text.length===0}
            className="btn btn-primary mt-3 mx-1"
            onClick={handleUppercaseclicked}
          >
            Convert to uppercase
          </button>
          <button disabled={text.length===0}
            className="btn btn-primary mt-3 mx-1"
            onClick={handleLowercaseclicked}
          >
            Convert to lowercase
          </button>
          <button disabled={text.length===0}
            className="btn btn-primary mt-3 mx-1"
            onClick={handleCopyToClipboard}
          >
            Copy to clipboard 
          </button>
        </div>
      </div>
      <div className="container">
        <h2>Your text summary</h2>
        <p>
          {text.split(" ").filter((element)=>{ return element.length!==0}).length} words and {text.length} characters
        </p>
        <p>{0.008 * text.split(" ").filter((element)=>{ return element.length!==0}).length} Minutes read</p>
        <h2>Preview</h2>
        <p>{text.length > 0  ? text : 'Enter somthing in above textbox to preview here..'}</p>
      </div>
    </>
  );
}
