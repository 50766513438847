import { useState } from "react";
import "./App.css";
import Alert from "./components/Alert";
 import About from "./components/About";
import Navbar from "./components/Navbar";
import TextForm from "./components/TextForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  const [mode, setMode] = useState("dark");
  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  };
  const toggleMode = () => {
    if (mode === "light") {
      setMode("dark");
      document.body.style.backgroundColor = "#000";
      document.body.style.color = "#fff";
      showAlert("Dark mode has been enable", "success");
      document.title = "Jitendra Solanki - Dark Mode";
      setInterval(() => {
        document.title = "Jitendra Solanki - Web Developer";
      }, 1500);
      setInterval(() => {
        document.title = "Jitendra Solanki - Web Designer";
      }, 1000);
    } else {
      setMode("light");
      document.body.style.backgroundColor = "#fff";
      document.body.style.color = "#000";
      showAlert("Light mode has been enable", "success");
      document.title = "Jitendra Solanki - Light Mode";
    }
  };
  return (
    <>
    <Router>
      <Navbar
        title="TextUtils"
        homeText="Home"
        mode={mode}
        toggleMode={toggleMode}
      />
       
      <div className="container mt-3">
      <Alert alert={alert} />
      <Routes>
          <Route exact path="/about" element={<About mode={mode}/>}/>
          <Route exact path="/" element={<TextForm showAlert={showAlert} heading="Enter the text below" />}/>
          
        </Routes>
        
      </div>
      </Router>
      
    </>
  );
}

export default App;
